<template>
    <div style="height: 550px">
        <NaverAlert v-if="popupStatus" @closePopup="closePopup" />
        <div class="mo_space_header">
            <div class="mo_header_box">
                <a>
                    <img src="/media/images/icon_back_24.svg" @click="backspace" />
                </a>
                <div></div>
            </div>
        </div>

        <div class="social_login">
            <div class="social_login_box">
                <h2>이미 어플레이즈 회원이시네요!</h2>
                <h3>아래 가입하신 계정을 확인해주세요.</h3>
            </div>
            <div class="social-content-box">
                <template>
                    <div v-for="(item, i) in mailList" :class="'ac-list' + i" :key="i" @click="selectId(item, i)">
                        <img :src="'/media/img/login/icon_social_' + item.comeFrom + '.svg'" />
                        <span class="social-id">{{ item.email }}</span>
                        <label for="check0">
                            <input :id="'id-input-' + i" class="social-id-list" type="radio" name="mail" />
                        </label>
                    </div>
                </template>
            </div>

            <div class="button-area">
                <button class="sign-up-next" @click="anotherAccount">선택한 아이디로 로그인</button>
                <button class="sign-up-btn" @click="signUp">신규 회원가입</button>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api';
import NaverAlert from '@/components/modal/Social/NaverAlert.vue';
import router from '@/router';
import $ from 'jquery';
import { updateUserAuth } from '@/service/api/niceApi';
export default defineComponent({
  props: ['userInfo', 'mailList', 'backStatus', 'encryptString'],

  components: {
    NaverAlert
  },

  data () {
    return {
      // mailList: null,
      selectNum: null,
      selectMailInfo: null,
      popupStatus: false
    };
  },

  mounted () {
    if (this.backStatus !== 'Y') {
      router.replace({ name: 'Login' });
    }
  },

  methods: {
    // 사용할 메일 선택
    selectId (item, idx) {
      if (this.selectNum == null) {
        $('#id-input-' + idx).prop('checked', true); // radio 체크 여부 변경
        $('.ac-list' + idx).css({ border: '1px solid #fff', background: 'rgb(225 225 225 / 16%)' }); // 박스의 스타일 변경
        $('.ac-list' + idx)
          .children('span')
          .css({ color: 'rgb(225 225 225 / 100%)', 'font-weight': '400' }); // 이메일 스타일 변경
        this.selectMailInfo = item;
        this.selectNum = idx;
      } else {
        // 기존 스타일 제거
        $('#id-input-' + this.selectNum).prop('checked', false);
        $('.ac-list' + this.selectNum).css({ border: '', background: 'rgb(225 225 225 / 4%)' });
        $('.ac-list' + this.selectNum)
          .children('span')
          .css({ color: 'rgb(225 225 225 / 60%)', 'font-weight': '100' });
        // 현재 스타일 적용
        $('#id-input-' + idx).prop('checked', true);
        $('.ac-list' + idx).css({ border: '1px solid #fff', background: 'rgb(225 225 225 / 16%)' });
        $('.ac-list' + idx)
          .children('span')
          .css({ color: 'rgb(225 225 225 / 100%)', 'font-weight': '400' });
        this.selectMailInfo = item;
        this.selectNum = idx;
      }
    },

    anotherAccount () {
      if (this.selectMailInfo.comeFrom === 'kakao') {
        const url = window.location.href;
        const uri =
          url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
            ? process.env.VUE_APP_DEV_CALLBACK_URL
            : process.env.VUE_APP_PRO_CALLBACK_URL;
        const crientId =
          url.indexOf('localhost') > 0 || url.lastIndexOf('dev') > 0
            ? process.env.VUE_APP_DEV_KAKAO_CRIENT_ID
            : process.env.VUE_APP_PRO_KAKAO_CRIENT_ID;

        // a 태그 생성
        const tag = document.createElement('a');
        tag.href =
          'https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=' +
          crientId +
          '&redirect_uri=' +
          uri +
          '&prompt=login';
        // socialToken 날리기

        // 카카오 로그인 페이지로 이동
        tag.click();
      } else if (this.selectMailInfo.comeFrom === 'email') {
        router.push({ name: 'Login' });
      } else {
        this.popupStatus = true;
      }
    },

    closePopup () {
      this.popupStatus = false;
      router.push({ name: 'Login' });
    },

    signUp () {
      updateUserAuth(this.encryptString, this.userInfo.userId, '', '').then((res) => {
        const { resultCd, result } = res.data;
        if (resultCd === '0000') {
          const { accessToken, refreshToken, comeFrom, userId: resUserId, email, userType } = result;
          this.$cookies.set('aToken', accessToken, '30d');
          this.$cookies.set('rToken', refreshToken, '30d');
          this.$cookies.set('comeFrom', comeFrom);
          this.$cookies.set('userId', resUserId);
          this.$cookies.set('email', email);
          this.$cookies.set('userType', userType);

          if (this.$cookies.get('autoLogin') === 'S') {
            this.$cookies.set('autoLogin', 'Y');
          }
          this.$router.push({ name: 'SocialFinished', params: { backStatus: 'Y' } });
        }
      });
    },

    backspace () {
      history.back();
    }
  }
});
</script>

<style scoped>
.social-content-box {
  width: 540px;
  max-height: 550px;
  overflow: auto;
  margin: 0 auto;
}

.social-content-box div {
  padding: 0 16px;
  width: 100%;
  height: 60px;
  background: hsla(0, 0%, 88.2%, 0.04);
  border-radius: 6px;
  margin-bottom: 12px;
  justify-content: flex-start;
  position: relative;
  display: flex;
  align-items: center;
}

.social-content-box div span {
  margin-left: 8px;
  color: hsla(0, 0%, 88.2%, 0.6);
  letter-spacing: 0;
  font-weight: 100;
}

label {
  display: inline-block;
}

input[type='radio'] {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
}

.button-area {
  width: 540px;
  overflow: auto;
  margin: 0 auto;
}

.button-area .sign-up-next {
  width: 100%;
  height: 52px;
  margin-bottom: 8px;
  background: #ff2d55;
  color: #fff;
}

.button-area .sign-up-btn {
  width: 100%;
  height: 52px;
  background: #151515;
  color: #fff;
}

@media all and (max-width: 500px) {
  .social-content-box {
    height: calc(100vh - 289px);
    margin-bottom: 120px;
    padding: 0 5%;
    width: 100%;
    max-height: none;
  }

  .social_login .button-area {
    position: fixed;
    left: 0%;
    bottom: 0%;
    width: 100%;
    height: 120px;
    padding: 8px 5% 0;
    background: #151515;
  }

  .button-area .sign-up-next {
    width: 100%;
    height: 52px;
    margin-bottom: 8px;
    background: #ff2d55;
  }

  .button-area .sign-up-btn {
    width: 100%;
    height: 52px;
    background: #151515;
  }
  /*
    @media all and (max-width: 300px) {
      .social_login_box h2 {
        font-size: 20px;
      }

      .social_login_box h3 {
        font-size: 13px;
      }

      .social_login .btn-area {
        height: 100px;
      }

      .btn-area .sign-up-next {
        height: 35px;
      }

      .btn-area .sign-up-btn {
        height: 35px;
      }
    }
    */
}
</style>
<style src="@/assets/css/content2.css"></style>
<style src="@/assets/css/social.css"></style>
<style src="@/assets/css/font.css"></style>
