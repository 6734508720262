<template>
    <div class="single-file-alert alert">
        <div class="single-file-box alert_box">
            <h3 class="box-title">
                로그인 창에서 네이버 아이콘 클릭해주세요.
            </h3>
            <p class="box-content">
                ※ 혹시 희망하는 계정으로 로그인이 안되는 경우,<br>
                <span class="txt-bold">네이버 접속하여 로그아웃 후,</span> 어플레이즈 로그인 창에서<br>
                네이버 아이콘 클릭하여 로그인 희망하는 계정을 입력해주세요.
            </p>
            <div class="single-file-check">
                <p @click="$emit('closePopup')">확인</p>
            </div>
        </div>
    </div>

</template>

<script>
export default {
  name: 'naverAlert'
};
</script>

<style scoped>
  .single-file-box .box-title {
    padding-bottom: 32px;
    color: rgba(255, 255, 255, 0.87);
    font-size: 16px;
    font-weight: 300;
    text-align: center;
    margin-bottom: 0px;
    padding: 0 5% 16px;
  }

  .single-file-box .box-content {
    font-size: 14px;
    font-weight: 300;
    color: rgba(255, 255, 255, 0.70);
    padding: 0 5% 5%;
  }

  .alert_box {
    padding-top: 32px !important;
  }

  .txt-bold {
    font-weight: 600;
  }

  @media all and (max-width: 375px) {
    .single-file-box .box-content {
      font-size: 13px;
    }
  }
</style>
